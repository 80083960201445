// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.delete {
  display: block;
  background-color: transparent;
  color: $red;
  text-decoration: underline;
  cursor: pointer; }

.modal:global(.ant-modal) {
   :global(.ant-modal-content) {
    border-radius: 10px;

     :global(.ant-modal-header) {
      border-radius: 10px;
      border-bottom: none;

       :global(.ant-modal-title) {
        font-size: 14px;
        font-weight: 700;
        color: $black; } }

     :global(.ant-modal-footer) {
      border-radius: 10px;
      border-top: none;

       :global(.ant-btn) {
        font-weight: 600;
        border-radius: 10px;
        padding: 4px 16px;

        &:hover, &:focus {
          color: $blue;
          border-color: $blue; } }

       :global(.ant-btn.ant-btn-primary) {
        border-color: $blue;
        background-color: $blue;
        margin-left: 12px;

        &:hover, &:focus {
          color: $white;
          border-color: $blue-active;
          background-color: $blue-active; } } } } }

.modalIcon:global(.anticon) {
  color: $red;
  font-size: 16px; }

.modalWrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }

.modalText {
  margin-left: 12px; }

.wrap {
  display: flex;
  flex-flow: row wrap;

  @media (min-width: $md-screen) {
    flex-flow: row nowrap; } }

.item {
  flex-shrink: 0;
  width: 100%;

  @media (min-width: $md-screen) {
    width: 50%; } }

.info {
  margin-right: 6px; }

.icon {
  width: 18px;
  height: 18px;
  margin-right: 10px; }

.nowrap {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 4px; }

.mr {
  margin-right: 8px; }

.problem {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  flex-shrink: 0;
  border-radius: 10px;

  &_1 {
    background-color: $blue; }

  &_2 {
    background-color: $yellow; }

  &_4 {
    background-color: $green; }

  &_5 {
    background-color: $red; } }

.gray {
  color: $gray2; }
