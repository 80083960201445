// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.header {
  width: 100%;
  height: 50px;
  background-color: $gray1; }

.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 24px;
  padding-right: 24px; }
