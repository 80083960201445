// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.widget {
  position: relative;
  width: 100%;
  background-color: $white;

  @media (min-width: $md-screen) {
    box-shadow: 2px 4px 20px rgba(206, 213, 219, 0.2);
    border-radius: 10px; } }

.title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 24px;
  margin-bottom: 8px; }

.loader:global(.anticon) {
  color: $blue;
  font-size: 20px; }

.head {
  position: sticky;
  top: 50px;
  z-index: 100;
  background-color: $white;
  box-shadow: 2px 4px 20px -4px rgba(206, 213, 219, 0.2); }

.date {
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 12px; }

.tableHead {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 56px; }

.item1 {
  width: 40%;
  padding-left: 24px; }

.item2 {
  width: 20%;
  padding-left: 24px; }

.item3 {
  width: 40%;
  padding-left: 24px; }

.divider:global(.ant-divider) {
  width: 100%;
  height: 1px;
  background-color: rgba(206, 213, 219, 0.2); }

.table .row:nth-child(odd) {
  background-color: #FAFAFA; }

.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 44px;
  background-color: $white;

  &Empty {
    justify-content: center; } }

.link {
  color: $blue;
  text-decoration: underline; }

.gray {
  color: $gray2; }
