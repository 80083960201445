// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.container {
  position: relative;
  width: 100%;
  flex-shrink: 0;

  @media (min-width: $md-screen) {
    width: auto; } }

.form {
  width: 100%;
  padding: 26px 24px 30px 24px;
  background-color: $white;

  @media (min-width: $md-screen) {
    border-radius: 10px;
    box-shadow: 2px 4px 20px rgba(206, 213, 219, 0.2); } }

.head {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px; }

.title {
  @media (max-width: $md-screen - 1px) {
    font-size: 14px; } }

.divider:global(.ant-divider) {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(206, 213, 219, 0.2);
  margin: 0;

  @media (min-width: $md-screen) {
    display: none; } }
