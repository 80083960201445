// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.container {
  position: relative;
  width: 100%;

   :global(.ant-picker-range-wrapper) {
    @media (max-width: $md-screen - 1px) {
      min-width: 0px !important;
      height: 310px;
      position: relative;
      overflow-y: scroll;
      box-shadow: 2px 4px 20px rgba(206, 213, 219, 0.6); }

     :global(.ant-picker-panel-container) {
      @media (max-width: $md-screen - 1px) {
        overflow: auto;
        box-shadow: initial; } } }


   :global(.ant-picker-panels) {
    @media (max-width: $md-screen - 1px) {
      flex-direction: column;
 } } }      // top: 240px


.input:global(.ant-picker.ant-picker-range) {
  width: 100%;
  height: 40px;
  border-radius: 10px;

  &:hover {
    border-color: $blue; }

   :global(.ant-picker-active-bar) {
    background: $blue; } }

.input:global(.ant-picker.ant-picker-range.ant-picker-focused) {
  border-color: $blue;

  &:hover {
    border-color: $blue; } }
