// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: transparent;
  height: 44px;
  cursor: pointer;

  &Text {
    margin: 0;
    color: $white; }

  &Img {
    width: 18px;
    height: 18px;
    margin-left: 6px; } }

.popover {
   :global(.ant-popover-inner) {
    border-radius: 10px;
    width: 240px;
    box-shadow: 2px 4px 20px rgba(206, 213, 219, 0.6);

     :global(.ant-popover-inner-content) {
      padding: 0; } } }

.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 44px;

  &Click {
    cursor: pointer; } }

.popupTitle {
  margin: 0;
  color: $gray4; }

.popupOwner {
  margin: 0;
  color: $blue; }

.popupOwners {
  margin: 0;
  color: $black; }

.divider:global(.ant-divider) {
  margin: 0; }
