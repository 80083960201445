// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.container {
  position: relative;
  width: 100%; }

.label {
  display: block;
  font-size: 13px;
  color: $gray2;
  line-height: 18px;
  margin-bottom: 6px;
  transition: color 0.3s ease-in-out;

  &Error {
    color: $red; }

  &Active {
    color: $blue; } }

.input:global(.ant-select),
.input:global(.ant-select:not(.ant-select-customize-input)),
.input:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)) {
  display: block;
  position: relative;
  z-index: 10;
  width: 100%;

   :global(.ant-select-arrow) {
    right: 16px; }

   :global(.ant-select-selector) {
    border-radius: 10px;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;

     :global(.ant-select-selection-placeholder) {
      line-height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: $gray4; }

     :global(.ant-select-selection-item) {
      line-height: 40px;
      font-size: 14px;
      color: $black; }

     :global(.ant-select-selection-search-input) {
      height: 40px; }

    &:hover {
      border-color: #D9D9D9; } } }

.input:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-color: $blue;

  &:hover {
    border-color: $blue; } }

.error.input:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-color: $red;

  &:hover {
    border-color: $red; } }

.error.input:global(.ant-select),
.error.input:global(.ant-select:not(.ant-select-customize-input)),
.error.input:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)) {
   :global(.ant-select-selector) {
    border-color: $red; } }
