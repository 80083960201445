// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.container {
  width: 100%;
  position: relative; }

.input:global(.ant-input) {
  width: 100%;
  display: block;
  height: 40px;
  border-radius: 10px;
  padding-left: 16px;
  padding-right: 36px;
  font-size: 14px;
  color: $black;

  &:hover {
    border-color: $blue; }

  &:focus {
    border-color: $blue; }

  &::placeholder {
    font-size: 14px;
    color: $gray4; } }

.icon {
  position: absolute;
  right: 16px;
  top: 11px;
  width: 18px;
  height: 18px; }
