// 100 - Thin (Hairline)
// 200 - Extra Light (Ultra Light)
// 300 - Light
// 400 - Normal
// 500 - Medium
// 600 - Semi Bold (Demi Bold)
// 700 - Bold
// 800 - Extra Bold (Ultra Bold)
// 900 - Black (Heavy)

@font-face {
    font-family: "OpenSans";
    src: url('../fonts/OpenSans-Bold.woff') format('woff'),
         url('../fonts/OpenSans-Bold.woff2') format('woff2');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "OpenSans";
    src: url('../fonts/OpenSans-SemiBold.woff') format('woff'),
         url('../fonts/OpenSans-SemiBold.woff2') format('woff2');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "OpenSans";
    src: url('../fonts/OpenSans-Regular.woff') format('woff'),
         url('../fonts/OpenSans-Regular.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
}