// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.bubbles {
  display: block;
  position: relative;
  width: 76px;
  height: 13px; }

.bubbles div {
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.white div {
  background-color: $white; }

.blue div {
  background-color: $blue; }

.bubbles div:nth-child(1) {
  left: 8px;
  animation: bubble1 0.6s infinite; }

.bubbles div:nth-child(2) {
  left: 8px;
  animation: bubble2 0.6s infinite; }

.bubbles div:nth-child(3) {
  left: 32px;
  animation: bubble2 0.6s infinite; }

.bubbles div:nth-child(4) {
  left: 56px;
  animation: bubble3 0.6s infinite; }

@keyframes bubble1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes bubble3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes bubble2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(24px, 0); } }
