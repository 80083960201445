// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.button {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 44px;
  height: 44px;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 15px;
  padding-bottom: 14px;
  background-color: transparent;
  cursor: pointer; }

.lines {
  width: 22px;
  height: 3px;
  border-radius: 3px;
  background-color: $white;
  transition: width 200ms;
  opacity: 1; }

.line1 {
  width: 22px; }

.line2 {
  width: 17px; }

.line3 {
  width: 12px; }
