// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.wrap {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 24px 44px 0;
  max-width: 280px; }

.alert:global(.ant-alert) {
  border-radius: 10px;
  margin-top: 16px;
  width: 280px;

   :global(.ant-alert-message) {
    font-weight: 700;
    font-size: 14px; }

   :global(.ant-alert-description) {
    font-weight: 400;
    font-size: 12px; } }
