// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.container {
  position: relative;
  width: 100%; }

.label {
  display: block;
  font-size: 13px;
  color: $gray2;
  line-height: 18px;
  margin-bottom: 6px;
  transition: color 0.3s ease-in-out;

  &Error {
    color: $red; }

  &Active {
    color: $blue; } }

.input:global(.ant-input) {
  display: block;
  position: relative;
  z-index: 10;
  width: 100%;
  border-radius: 10px;
  padding: 0 16px;
  font-size: 14px;
  color: $black;

  &:hover {
    border-color: #D9D9D9; }

  &:focus {
    border-color: $blue; }

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: $gray4; } }

.error:global(.ant-input) {
  border-color: $red;

  &:hover {
    border-color: $red; }

  &:focus {
    border-color: $red; } }

.small:global(.ant-input) {
  height: 40px; }

.big:global(.ant-input) {
  height: 48px; }
