// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.sidebar {
  width: 100vw;
  background-color: $white;
  overflow-y: auto;

  @media (min-width: $md-screen) {
    width: 320px;
    box-shadow: 20px 0px 100px rgba(206, 213, 219, 0.7); }

  @media (min-width: $lg-screen) {
    width: 212px;
    box-shadow: none; } }

.wrap {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%; }

.row {
  flex-shrink: 0;
  width: 100%;
  height: 56px;

  @media (min-width: $lg-screen) {
    height: 40px; } }

.link {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  background-color: transparent;

  &Text {
    @media (max-width: $lg-screen - 1px) {
      font-size: 15px; } } }

.companyInfo {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start; }

.divider:global(.ant-divider) {
  margin: 0;
  flex-shrink: 0; }

.first {
  @media (min-width: $md-screen) {
    margin-top: 20px; } }

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }

.logout {
  margin-top: auto; }

.hover {
  background-color: $white;
  cursor: pointer;

  &:hover {
    background-color: #FAFAFA; } }

.big {
  height: 80px;

  @media (min-width: $md-screen) {
    display: none; } }

.hidden,
.hidden:global(.ant-divider) {
  display: block;

  @media (min-width: $lg-screen) {
    display: none; } }

.black {
  color: $black; }

.gray {
  color: $gray4; }

.red {
  color: $red; }

.img {
  width: 24px;
  height: 24px;

  @media (min-width: $lg-screen) {
    width: 18px;
    height: 18px; } }

.mr {
  margin-right: 16px; }

.version {
  @media (max-width: $lg-screen - 1px) {
    font-size: 13px; } }
