// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.widget {
  position: relative;
  width: 100%;
  background-color: $white;

  @media (min-width: $md-screen) {
    box-shadow: 2px 4px 20px rgba(206, 213, 219, 0.2);
    border-radius: 10px; } }

.title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 8px;
  margin-top: 24px; }

.loader:global(.anticon) {
  color: $blue;
  font-size: 20px; }

.tableHead {
  position: sticky;
  top: 50px;
  z-index: 100;
  background-color: $white;
  box-shadow: 2px 4px 20px -4px rgba(206, 213, 219, 0.2);
  padding-top: 16px; }

.search {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 8px; }

.head {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  height: 56px; }

.itemHead {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center; }

.item2.itemHead, .item3.itemHead, .item4.itemHead {
  display: none;

  @media (min-width: $md-screen) {
    display: flex; } }

.item5.itemHead {
  @media (max-width: $md-screen - 1px) {
    text-align: right; } }

.item1, .item2, .item3, .item4, .item5 {
  min-width: 120px;
  width: 50%;
  padding: 0 20px 0 0;

  @media (min-width: $md-screen) {
    width: 20%; } }

.table {
  width: 100%; }

.row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  background-color: $white;
  padding-left: 24px;
  padding-right: 24px;

  @media (min-width: $md-screen) {
    flex-flow: row nowrap; }

  &Empty {
    flex-flow: row nowrap;
    justify-content: center; } }

.content .row:nth-child(odd) {
  background-color: #FAFAFA; }

.content .row {
  @media (max-width: $md-screen - 1px) {
    padding-bottom: 10px; } }

.itemBody {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 64px;

  &Empty {
    flex-flow: row nowrap;
    align-items: center; } }

.item2.itemBody, .item3.itemBody, .item4.itemBody {
  order: 5;
  width: 100%;
  min-height: 30px;
  color: $gray2;

  @media (min-width: $md-screen) {
    min-height: 60px;
    order: initial;
    width: 20%;
    color: $black; } }

.item1.itemBody {
  cursor: pointer; }

.item5.itemBody {
  @media (max-width: $md-screen - 1px) {
    text-align: right; } }

.item1.itemBody, .item5.itemBody {
  @media (max-width: $md-screen - 1px) {
    min-height: 80px; } }

.itemInfo {
  margin-right: 6px;
  color: $black;

  @media (min-width: $md-screen) {
    display: none; } }

.icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  flex-shrink: 0; }

.icon2 {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  flex-shrink: 0; }

.problem {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  flex-shrink: 0;
  border-radius: 10px;

  &_1 {
    background-color: $blue; }

  &_2 {
    background-color: $yellow; }

  &_4 {
    background-color: $green; }

  &_5 {
    background-color: $red; } }

.big {
  @media (max-width: $md-screen - 1px) {
    font-size: 14px; } }

.total {
  @media (max-width: $md-screen - 1px) {
    margin-top: 8px;
    font-size: 14px; } }

.totalInfo {
  // margin-top: 2px

  @media (max-width: $md-screen - 1px) {
    margin-top: 8px; } }

.mr {
  margin-right: 8px; }

.wrap {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  @media (min-width: $md-screen) {
    width: 100%; } }

.nowrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @media (min-width: $md-screen) {
    width: 100%; } }

.column {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;

  @media (min-width: $md-screen) {
    align-items: flex-start; } }

.textWrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.gray {
  color: $gray2; }

.blue {
  color: $blue;
  text-decoration: underline; }
