// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.money {
  margin-bottom: 12px; }

.wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center; }

.loader:global(.anticon) {
  color: $blue;
  font-size: 20px; }

.info {
  margin-top: 2px;
  text-align: center;

  @media (min-width: $md-screen) {
    text-align: left; } }

.divider:global(.ant-divider) {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(206, 213, 219, 0.2);
  margin: 12px 0 24px 0; }
