// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
@import "./_fonts.scss";

*,
*::before,
*::after {
  box-sizing: border-box; }

*:focus {
  outline: none; }

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

#app {
  position: relative;
  z-index: 100; }

#alerts {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  overflow: hidden;
  z-index: 200; }

#overlay {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 300; }

body {
  position: relative;
  font-family: 'OpenSans', sans-serif;
  font-weight: 400;
  color: $black;
  background-color: $bg; }

picture,
main {
  display: block; }

button {
  margin: 0;
  padding: 0;
  border: 0; }

p {
  margin: 0; }

input[type="text"],
input[type="password"],
input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  display: none;
  visibility: hidden;
  pointer-events: none;
  margin: 0;
  width: 0;
  height: 0;
  background-color: transparent; }
