// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.overlay {
  position: relative;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba(0, 0, 0, 0.4); }

.wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }

.fadeEnter {
  opacity: 0; }

.fadeEnterActive {
  opacity: 1;
  transition: opacity 0.3s ease; }

.fadeExit {
  opacity: 1; }

.fadeExitActive {
  opacity: 0;
  transition: opacity 0.3s ease; }
