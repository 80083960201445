// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.account {
  position: relative; }

.header {
  position: fixed;
  z-index: 500; }

.logoWrap {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center; }

.logo {
  width: 49px;
  height: 20px; }

.wrap {
  position: absolute;
  z-index: 200;
  content: "";
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.sidebar {
  position: fixed;
  z-index: 400;
  content: "";
  top: 50px;
  left: 0;
  bottom: 0; }

.layout {
  position: relative;
  z-index: 300;
  width: 100%;
  transition: 0.3s ease;
  transition-property: padding;
  padding-top: 50px;

  &Padding {
    @media (min-width: $lg-screen) {
      padding-left: 212px; } } }

.slideEnter {
  opacity: 0;
  transform: translateX(-120px); }

.slideEnterActive {
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 300ms ease, transform 300ms ease; }

.slideExit {
  opacity: 1;
  transform: translateX(0px); }

.slideExitActive {
  opacity: 0;
  transform: translateX(-120px);
  transition: opacity 300ms ease, transform 300ms ease; }
