// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.main {
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden; }

.wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%; }

.banner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 0;
  height: 100%;
  background-color: $blue;
  overflow-y: auto;

  @media (min-width: $lg-screen) {
    width: 50%; }

  &Wrap {
    width: 80%;
    margin: auto;
    padding: 40px 0 40px 0;

    @media (min-width: $xl-screen) {
      width: 70%; } } }

.logo {
  width: 97px;
  height: 40px; }

.title {
  margin-top: 34px;
  color: $white; }

.subtitle {
  color: $white;
  margin-top: 8px; }

.hero {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $white;
  overflow-y: auto;

  @media (min-width: $lg-screen) {
    width: 50%; } }

.body {
  flex-shrink: 0;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  &Wrap {
    padding: 24px;

    @media (min-width: $lg-screen) {
      padding: 0;
      max-width: 320px;
      margin: auto; } } }

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 50px;
  background-color: $gray1;

  @media (min-width: $lg-screen) {
    display: none; }

  &Logo {
    width: 49px;
    height: 20px; } }

.footer {
  flex-shrink: 0;
  width: 100%;
  height: 50px;

  &Wrap {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 100%; }

  &Text {
    text-align: center;
    color: $gray4; } }
