// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.link {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 36px;
  color: $black;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 4px;
  flex-shrink: 0;
  user-select: none;

  &:hover {
    color: $blue; } }

.active {
  color: $blue;
  background-color: $white;
  box-shadow: 0px 2px 20px 2px rgba(206, 213, 219, 0.18); }
