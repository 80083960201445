// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  border-radius: 10px;
  background-color: $blue;
  transition: 0.3s ease background-color;
  user-select: none;

  &:hover {
    background-color: $blue-active; }

  &Disabled {
    background-color: $blue-disabled;
    cursor: not-allowed;

    &:hover {
      background-color: $blue-disabled; } } }

.text {
  color: $white; }

.big {
  height: 48px; }

.small {
  height: 40px; }

.loader:global(.anticon) {
  color: $white;
  font-size: 20px; }
