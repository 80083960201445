// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.companies {
  background-color: $white;

  @media (min-width: $md-screen) {
    margin: 30px;
    border-radius: 10px;
    box-shadow: 2px 4px 20px rgba(206, 213, 219, 0.2); } }

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  padding: 0 24px;

  @media (min-width: $md-screen) {
    padding: 0 30px; } }

.title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.name {
  display: block; }

.divider:global(.ant-divider) {
  margin: 0; }

.active {
  cursor: pointer; }

.blue {
  color: $blue; }

.gray {
  color: $gray4; }
