// Sizes
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Colors
$black: #121926;
$white: #ffffff;
$blue: #007AFF;
$blue-active: #0054DC;
$blue-disabled: #B8CEE5;
$gray1: #364254;
$gray2: #5E7488;
$gray3: #CED5DB;
$gray4: #ABAFB2;
$red: #EE1549;
$pink: #FFDBDB;
$green: #2ED75D;
$yellow: #FFA800;
$bg: #F5F8FD;
@function lineHeight($font-size) {
  $result: $font-size * 1.5px;
  @return $result; }
.wrap {
  display: flex;
  flex-flow: row wrap;

  @media (min-width: $md-screen) {
    display: block;
    column-gap: 24px;
    column-width: 380px; } }

.forms {
  display: block;
  width: 100%;

  @media (min-width: $md-screen) {
    display: block;
    min-width: 380px;
    max-width: none;
    padding-bottom: 24px;
    -webkit-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid; } }

.second {
  @media (min-width: $md-screen) {
    margin-left: 24px; } }
